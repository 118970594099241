// * Import Poppins
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

// * Import reset styles
@import './stylesheets/reset';

// * Import styles for quill editor

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: font-url('assets/fonts/materials.woff2') format('woff2');
}


@font-face {font-family: 'Avenir Roman'; src: url('assets/fonts/Avenir-Roman.woff2') format('woff2'), url('assets/fonts/Avenir-Roman.woff') format('woff'), url('assets/fonts/Avenir-Roman.ttf') format('truetype'); font-weight: normal; font-style: normal; font-display: swap;}

app-root .material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.mat-typography * {
    font-family: "Avenir Roman", sans-serif !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Raleway", sans-serif !important;
    }
}

.action-button {
    cursor: pointer;
}

.mat-typography {
    line-height: unset;
}

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined' !important;
}



* {
    font-family: 'Avenir Roman', sans-serif;
    color: #262626;
    letter-spacing: normal !important;
}

body {
    background: white;

    height: 100%;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    width: 8px;
    background: rgb(243 249 247);
}

::-webkit-scrollbar-thumb {
    background: #262626;
}

button.btn-green {
    background: #262626;
    color: white;

    border: none;
    outline: none;
    border-radius: 50px;

    width: 72px;
    height: 40px;
    transition: all .3s;
    cursor: pointer;
    font-weight: 800;
    font-size: 16px;

    &:hover {
        background: #262626f7;
    }

    &:active {
        background: #1f1f1f;
    }

    &.white {
        color: #262626;
        background-color: #fff;

        &:hover {
            background: #ededed;
        }

        &:active {
            background: #d2d2d2;
        }
    }

}

html {
    height: 100%;
}

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// .mat-mdc-dialog-container .mdc-dialog__surface {
//     border-radius: 20px !important;
// }

::ng-deep mat-dialog-container {
    border-radius: 0px !important;
 }

 .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 0px;
  }

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
    background: rgba(38, 38, 38, 1);
    opacity: 0.7;
}

.mdc-tooltip__surface {
    background-color: #102C58 !important;
    pointer-events: none;
}

// <adaptation and setting for FLOW dialog

body ::ng-deep app-flow-dialog app-flow-form {
    display: block;
    width: 850px;
}

body ::ng-deep app-flow-dialog app-flow-details {
    display: block;
    min-width: 850px; //630
    min-height: 750px;
}

body:has(app-flow-dialog) {
    .mdc-tooltip__surface {
        background-color: #e9e9e99c !important;
        pointer-events: none;
        color: black !important;
    }
}

@media screen and (max-width: 800px) {

    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 0px !important;
    }
    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 0px !important;
    }

    body::ng-deep .mat-mdc-dialog-container  {
   
        min-width: 100vw !important;
       
    }
}

@media screen and (max-width: 800px) {
    ::ng-deep {
        .cdk-overlay-pane {
            min-width: 100vw !important;
        }
     }
}

// }

// @media screen and (max-height: 700px) and (max-width: 400px) {
//     .mat-mdc-dialog-container .mdc-dialog__surface {
//         border-radius: 0px !important;
//     }

//     .cdk-overlay-pane {
//         max-width: 100vw !important;
//     }
// }

// </adaptation and setting for FLOW dialog